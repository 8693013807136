import React from 'react'
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import { getCanonicalURL } from '../../adapters/helpers/Utils';
import { validURL } from '../../adapters/helpers/Url';
import { OrganizationConstants } from '../../adapters/helpers/Constants';
import PropTypes from 'prop-types'
import Head from 'next/head';
import { optimizeImage } from '../../adapters/helpers/pagespeedHelper';

export default function Meta(props) {
    const { metadata, pagePath } = props;
    const title = metadata?.metaTitle;
    const description = metadata?.metaDescription;
    const keywords = metadata?.metaKeywords;
    const pagetype = metadata?.pageType;
    const noindex = metadata?.noIndex;
    const nofollow = metadata?.noFollow;
    const openGraphPageTitle = metadata?.openGraphPageTitle;
    const openGraphDescription = metadata?.openGraphDescription;
    const socialAccounts = metadata?.socialsAccounts?.fields;
    let metaRobots = [];
    metaRobots.push(noindex ? 'noindex' : 'index');
    metaRobots.push(nofollow ? 'nofollow' : 'follow');
    let openGraphImageUrl = null;
    let logoUrl = null;

    if (metadata && metadata.openGraphImage && metadata.openGraphImage.fields) {
        openGraphImageUrl = optimizeImage(ImageFactory.imageContentfullSrc(metadata?.openGraphImage?.fields?.asset?.fields?.file?.url));
    }
    if (metadata && metadata.logo && metadata.logo.fields) {
        logoUrl = optimizeImage(ImageFactory.imageContentfullSrc(metadata?.logo?.fields?.asset?.fields?.file?.url));
    }
    let canonical = generateCanonical(metadata, pagePath);
    return (
        <>
            <Head>
                <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1' />
                <title>{title}</title>
                <link rel='manifest' fetchPriority='high' href='/static/manifest.webmanifest' crossOrigin='use-credentials' />
                <meta charSet='UTF-8' />
                <meta name='description' content={description} />
                <meta name='robots' content={metaRobots} />
                <meta name='theme-color' content='#0057B8' />
                <meta name='pagetype' content={pagetype} />
                {keywords &&
                    <meta name='keywords' content={keywords} />}
                {openGraphPageTitle &&
                    <meta property='og:title' content={openGraphPageTitle} />}
                {openGraphDescription &&
                    <meta property='og:description' content={openGraphDescription} />}
                {openGraphImageUrl &&
                    <meta property='og:image' content={openGraphImageUrl} />}
                {canonical &&
                    <meta property='og:url' content={canonical} />}
                {canonical &&
                    <link rel='canonical' href={canonical} />}
            </Head>
            <div itemScope itemProp='Organization' itemType='http://schema.org/Organization'>
                {canonical &&
                    <link itemProp='url' href={canonical} />}
                <link itemProp='name' content={OrganizationConstants.brand} />
                <link itemProp='parentOrganization' content={OrganizationConstants.parentOrganization} />
                {logoUrl &&
                    <link itemProp='logo' href={logoUrl}></link>}
                {socialAccounts && socialAccounts.facebookUrl &&
                    <link itemProp='sameAs' href={socialAccounts.facebookUrl} />}
                {socialAccounts && socialAccounts.twitterUrl &&
                    <link itemProp='sameAs' href={socialAccounts.twitterUrl} />}
                {socialAccounts && socialAccounts.youtubeUrl &&
                    <link itemProp='sameAs' href={socialAccounts.youtubeUrl} />}
                {socialAccounts && socialAccounts.instagramUrl &&
                    <link itemProp='sameAs' href={socialAccounts.instagramUrl} />}
            </div>
            <div itemScope itemProp='WebSite' itemType='http://schema.org/WebSite'>
                {process.env.WEBSITE_CANNONICAL_URL &&
                    <link itemProp='url' href={process.env.WEBSITE_CANNONICAL_URL} />}
                <link itemProp='name' content={OrganizationConstants.brand} />
            </div>
        </>
    );
}

Meta.propTypes = {
    metadata: PropTypes.object,
    pagePath: PropTypes.string,
};

function generateCanonical(metadata, pagePath) {
    let canonical = metadata?.canonicalUrl;
    if (canonical == null) {
        canonical = pagePath;
    }
    return validURL(canonical) ? canonical : getCanonicalURL(canonical);
}
